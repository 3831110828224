<template>
  <v-app class="bg-login" id="auth">
    <v-main>
      <v-container class="login-container" fluid>
        <v-row align-content="center" class="login-container__row">
          <v-col
            class="login-container__col left hidden-sm-and-down"
            cols="12"
            md="6"
          >
            <div class="left__text">
              <p class="uppercase">Portal</p>
              <p class="uppercase">Administrativo</p>
            </div>
          </v-col>
          <!-- FORM LOGIN -->
          <v-col class="login-container__col right" cols="12" md="6">
            <v-row>
              <v-col cols="8" offset="2">
                <div
                  class="logo-container d-flex flex-column justify-center align-center"
                >
                  <img
                    src="../../assets/logo_av_securities.svg"
                    alt="Logo Av"
                    class="logo-container__image"
                  />
                  <p class="logo-container__text">
                    ¡Bienvenido de nuevo! Por favor, ingrese a su cuenta.
                  </p>
                </div>
              </v-col>
              <v-col cols="10" offset="1">
                <ValidationObserver v-slot="{ invalid }">
                  <form @submit.prevent="handleSubmit">
                    <v-row>
                      <v-col cols="12">
                        <!--EMAIL -->
                        <ValidationProvider
                          name="Correo electrónico"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            label="Correo electrónico"
                            dense
                            v-model="userData.email"
                            placeholder="Correo electrónico"
                            :error-messages="errors[0]"
                            autofocus
                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <!-- PASSWORD -->
                        <ValidationProvider
                          name="Password"
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            v-model="userData.password"
                            label="Contraseña"
                            placeholder="Contraseña"
                            :type="showPassword ? 'text' : 'password'"
                            @click:append="showPassword = !showPassword"
                            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                            :error-messages="errors[0]"

                          />
                        </ValidationProvider>
                      </v-col>
                      <v-col cols="12">
                        <v-row justify="center">
                          <!-- <vue-recaptcha
                            v-if="recaptchaShow"
                            sitekey="Your key here"
                            :loadRecaptchaScript="true"
                          ></vue-recaptcha> -->
                        </v-row>
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex flex-row justify-space-between align-baseline"
                      >
                        <div></div>
                        <!--
                        <v-checkbox
                          v-model="userData.remember"
                          label="Recuérdame"
                        ></v-checkbox>
                        -->
                        <router-link
                          class="text-left"
                          :to="{ name: 'ForgotPassword' }"
                          >¿Olvidó su contraseña?</router-link
                        >
                      </v-col>
                      <v-col class="d-flex justify-end" cols="12">
                        <!-- <router-link
                          class="text-left"
                          :to="{ name: 'ForgotUser' }"
                          >¿Olvidó su usuario?</router-link
                        > -->
                        <!-- <router-link
                          class="text-left"
                          :to="{ name: 'ForgotPassword' }"
                          >¿Olvidó su contraseña?</router-link
                        > -->
                      </v-col>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-btn
                          x-large
                          color="primary"
                          type="submit"
                          class="login-button"
                          rounded
                          :loading="loading"
                          :disabled="invalid"
                          >Login</v-btn
                        >
                      </v-col>
                    </v-row>
                  </form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapState } from 'vuex'
// import VueRecaptcha from 'vue-recaptcha'
import SettingsService from '@/services/setting'
import MenuService from '@/services/menu'

export default {
  // components: { VueRecaptcha },
  data() {
    return {
      userData: {
        email: '',
        password: '',
        remember: false
      },
      showPassword: false,
      recaptchaShow: false,
      sections: []
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('auth', ['user']),
    imgCard() {
      return ''
    }
  },
  created() {
    // this.getSettings()
  },
  methods: {
    ...mapActions('auth', ['signIn']),
    ...mapActions('menu', ['setMenu']),
    async handleSubmit() {
      try {
        await this.signIn(this.userData)
        if (this.user.admin) {
          await this.populateMenuSections()
          this.$router.push({
            name: 'Dashboard',
            params: { id: this.user.id }
          })
        } else {
          this.$dialog.notify.error('No autorizado')
        }
      } catch (e) {
        this.$dialog.notify.error(e)
      }
    },
    async populateMenuSections() {
      try {
        const { data } = await MenuService.fetch({ 'filter[type]': 'Admin' })
        this.sections = data.data
        this.setMenu(this.sections)
      } catch (e) {
        this.$dialog.notify.error(e)
      }
    },
    async getSettings() {
      const { data } = await SettingsService.fetch({
        'filter[key]': 'recaptcha'
      })
      this.recaptchaShow = data.data[0].value
    }
  }
}
</script>
<style>
#auth label.v-label {
  transform: translateY(-18px) scale(.75);
}
</style>
<style scoped>
.theme--light.v-application.bg-login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background: rgba(8, 48, 84, 1);
  background-size: cover !important;
  background-repeat: no-repeat;
  /* font-family: Lato; */
}
.login-container {
  min-height: 100vh;
}
.login-container__row {
  min-height: 100vh;
}
.login-container__col {
  min-height: 100vh;
}
.left {
  /* background-color: rgb(1, 1, 75); */
  /* background: url('../../assets/AV_001_inicio.png') no-repeat center/100% !important; */
  background-image: url('../../assets/AV_001_inicio_sinletras.jpg');
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  background-size: cover;
  background-color: rgb(1, 1, 75);

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100vh;
}
.left__text {
  /* width: 350px; */

  text-align: left;
  font: normal normal 300 44px/52px Lato !important;
  letter-spacing: -1.1px !important;
  color: #0294de !important;
  opacity: 1;
}
.right {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.test {
  background-color: red;
}
.form {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  justify-content: space-between;
}
.links a {
  font: normal normal normal 16px/27px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
  text-decoration: none;
}
.login-button {
  width: 208px;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.logo-container__text {
  margin-top: 20px;
  text-align: center;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}
.logo-container__image {
  width: 90%;
}
.bg-login {
  font: normal normal normal 16px/27px Lato;
}
.form label {
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0px;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 979px) {
  .logo-container__text {
    font: normal normal normal 16px/18px Lato;
  }
  .logo-container__text_two {
    font: normal normal normal 16px/18px Lato;
  }
  .text-left {
    font: normal normal normal 12px/27px Lato;
  }
}
</style>
